module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Another Harriet","short_name":"Another Harriet","lang":"en","start_url":"/","background_color":"#FFF5F1","theme_color":"#221D23","display":"standalone","icon":"src/assets/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"798b3213d81dbca6a54098610f98c323"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"anotherharriet","accessToken":"MC5ZS1ByZ3hBQUFDSUE2WGtJ.Vl81fu-_vQFydTnvv714EO-_vV7vv73vv73vv73vv73vv73vv73vv73vv70rKe-_ve-_vTtzJQDvv73vv70","schemas":{"page":{"Main":{"uid":{"type":"UID","config":{"label":"slug"}},"meta_title":{"type":"StructuredText","config":{"single":"heading6","label":"Meta Title"}},"meta_description":{"type":"StructuredText","config":{"single":"paragraph","label":"Meta Description"}},"subtitle":{"type":"StructuredText","config":{"single":"heading6","label":"Subtitle"}},"title":{"type":"StructuredText","config":{"single":"heading1","label":"Title"}},"content":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading1, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, rtl","allowTargetBlank":true,"label":"Content"}}}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-0YSBXP5Y5Z"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
